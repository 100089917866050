<template>
  <div class="label label-control">
    <slot></slot><span v-if="required" class="required">*</span>
  </div>
</template>

<script>
export default {
  name: 'label-control',
  props: {
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.label-control
  .required
    padding-left: 2px
    color: $warning-color
</style>