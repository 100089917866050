<template>
  <div
    :class="{ 'has-error': hasError }"
    class="radio-control">
      <div
        v-for="(option) in options"
        :key="option.key"
        @click="onClick($event, option.key)"
        class="control radio">
          <input
            type="radio"
            v-model="inputValue"
            :value="option.key"/>
          {{ option.label }}
      </div>
  </div>
</template>

<script>
export default {
  name: 'radio-control',
  props: {
    value: {
      default: ''
    },
    options: {
      type: Array
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value === '') {
          value = null
        }
        this.$emit('input', value)
      }
    },
    hasError () {
      return this.error
    }
  },
  methods: {
    onClick (Event, key) {
      this.inputValue = key
    }
  }
}
</script>

<style lang="sass">
.radio-control
  .control
    cursor: pointer
</style>