<template>
  <div
    :class="{ 'has-error': hasError }"
    class="control text text-control">
      <input
        type="text"
        v-model="inputValue"
        :placeholder="placeholder"
        :maxlength="maxlengthCalc"
        @input="onInput($event)"
        @change="onChange($event)"
        @focus="onFocus($event)"
        @keypress="onKeyPress($event)"
        ref="control" />
  </div>
</template>

<script>
export default {
  name: 'text-control',
  props: {
    value: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    isEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: null,
      inputValue: ''
    }
  },
  computed: {
    hasError () {
      return this.error || !this.valid()
    },
    maxlengthCalc () {
      if (!this.isEmail && fn.isInteger(this.max, 1) && this.max > this.min) {
        return this.max
      } else {
        return null
      }
    }
  },
  methods: {
    valid () {
      if (this.isEmail) {
        return fn.isEmpty(this.inputValue) || fn.isEmail(this.inputValue)
      } else {
        return fn.isEmpty(this.inputValue) || fn.isString(this.inputValue, this.min, this.maxlengthCalc)
      }
    },
    format (text) {
      if (this.isEmail) {
        text = fn.lower(text)
      }
      return fn.trim(text)
    },
    unformat (input) {
      return fn.toString(input)
    },
    typecheck (val) {
      return true
    },
    onFocus (Event) {
      this.$refs.control.select()
    },
    onInput (Event) {
      this.text = this.unformat(this.inputValue)
      this.$emit('input', this.text || null )
    },
    onChange (Event) {
      if (this.valid()) {
        this.inputValue = this.format(this.text)
      }
    },
    onKeyPress (Event) {
      if (!this.typecheck(Event.key)) {
        event.preventDefault()
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(text) {
        if (text !== this.text) {
          this.text = text
          this.inputValue = this.format(text)
        }
      }
    }
  }
}
</script>

<style lang="sass">
</style>