<template>
  <div
    @click="onClick()"
    :class="{ 'has-error': hasError }"
    class="control checkbox checkbox-control">
      <input
        type="checkbox"
        v-model="inputValue"
        :true-value="true"
        :false-value="false" /><slot></slot>
  </div>
</template>

<script>
export default {
  name: 'checkbox-control',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return fn.isTrue(this.value)
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    hasError () {
      return this.error
    }
  },
  methods: {
    onClick (Event) {
      this.toggle()
    },
    toggle () {
      this.inputValue = !this.inputValue
    }
  }
}
</script>

<style lang="sass">
</style>