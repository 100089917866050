<template>
  <div
    :class="{ 'has-error': hasError }"
    class="control text number-control">
      <input
        type="text"
        v-model="inputValue"
        :placeholder="placeholder"
        @input="onInput($event)"
        @change="onChange($event)"
        @focus="onFocus($event)"
        @keypress="onKeyPress($event)"
        @paste.prevent
        ref="control" />
  </div>
</template>

<script>
export default {
  name: 'number-control',
  props: {
    value: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },

    // not implemented yet, decimals > 0 defines a float
    decimals: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      number: null,
      inputValue: ''
    }
  },
  computed: {
    hasError () {
      return this.error || !this.valid()
    }
  },
  methods: {
    valid () {
      return fn.isEmpty(this.inputValue) || fn.isInteger(this.unformat(this.inputValue), this.min, this.max)
    },
    format (number) {
      return fn.trim(number)
    },
    unformat (input) {
      return parseInt(input) || ''
    },
    typecheck (val) {
      return /^\d*$/.test(val)
    },
    onFocus (Event) {
      this.$refs.control.select()
    },
    onInput (Event) {
      this.number = this.unformat(this.inputValue)
      this.$emit('input', this.number || null )
    },
    onChange (Event) {
      if (this.valid()) {
        this.inputValue = this.format(this.number)
      }
    },
    onKeyPress (Event) {
      if (!this.typecheck(Event.key)) {
        event.preventDefault()
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(number) {
        if (number !== this.number) {
          this.number = fn.isInteger(number) ? number : null
          this.inputValue = this.format(number)
        }
      }
    }
  }
}
</script>

<style lang="sass">
</style>