<template>
  <div
    :class="{ 'has-error': hasError }"
    class="control select select-control">
      <select
        v-model="inputValue">
          <option
            v-for="(option) in options"
            :key="option.key"
            :value="option.key">
              {{ option.label }}
          </option>
      </select>
  </div>
</template>

<script>
export default {
  name: 'select-control',
  props: {
    value: {
      default: ''
    },
    options: {
      type: Array
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value === '') {
          value = null
        }
        this.$emit('input', value)
      }
    },
    hasError () {
      return this.error
    }
  }
}
</script>

<style lang="sass">
</style>